<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue"><i class="fas fa-stopwatch margin-r" aria-hidden="true"></i>View & edit timesheets</h3>
          <div class="d-flex justify-content-start align-items-center" align="left">
            <div class="col-md-3 p-2" align="left">
             <!-- <v-date-picker v-model="PostForm.date" mode="date" :model-config="modelConfig" is-required @dayclick="GetTimeSheet()">
                <template v-slot="{ inputValue, inputEvents    }">
                  <input class="form-control" :value="inputValue" v-on="inputEvents" />
                </template>
              </v-date-picker> -->
            </div>
          </div>
          <div v-if="ListTimeSheets.status != 'false'" class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <div style="display:inline !important">
                <div class="d-flex justify-content-between"></div>
              </div>
              <!-- Tabla-->
              <div  class="">
                <table id="timesheet-table" class="table table-striped  responsive">
                  <thead>
                    <tr class="text-start">
                      <th scope="col" class="thead-bottom">
                        <span class="text-blue">Project</span>
                      </th>
                      <th scope="col" class="thead-bottom">
                        <span class="text-blue">Time</span>
                      </th>
                      <th scope="col" class="thead-bottom">
                        <span class="text-blue">Date</span>
                      </th>
                      <th scope="col" class="thead-bottom">
                        <span class="text-blue">Status</span>
                      </th>
                      <th scope="col" class="thead-bottom">
                        <span class="text-blue">Action</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="mb-special" v-if="ListTimeSheets.status != 'false'">
                    <tr class="text-start" v-for="(obj, key, index) in ListTimeSheets.list" :key="index">
                      <td class="text-start">{{obj.project}}</td>
                      <td class="text-start">{{ $filters.UnixToDateHours(obj.init) }} - {{ $filters.UnixToDateHours(obj.finaly) }}</td>
                      <td class="text-start">{{obj.date}}</td>
                      <td class="text-start">
                        <p v-if="obj.status == '1'" class="special-badget-status">Approved</p>
                        <p v-if="obj.status == '0'" class="special-badget-status-inactive">Not approved</p>
                      </td>
                      <td class="text-center">
                        <div class="d-flex justify-content-around">
                          <a  href="#" class="text-blue" id="dropdownMenuActions" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </a>
                          <ul class="drop-actions dropdown-menu" aria-labelledby="dropdownMenuActions">
                             <li>
                              <a v-if="obj.status == '0'" class="dropdown-item" v-on:click="UpdateTimeSheetStatus(obj.id)">Approve timesheet</a>
                              <a v-if="obj.status == '1'" class="dropdown-item" >No action</a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- Paginador -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import Api from '@/services/Request'; 
  import Multiselect from '@vueform/multiselect'
  import "datatables.net-dt/js/dataTables.dataTables"
  import "datatables.net-dt/css/jquery.dataTables.min.css"
  import $ from 'jquery'; 

  export default
  {
    name: 'Project',
    components: 
    {
      Multiselect,
    },
    data: () => (
    {
      PostForm:
      {
        date: new Date(),//new Date().toISOString().slice(0, 10)
      },
      ListMembers: 
      {
        status:"false",
        list: {}
      },
      ListProjects: 
      {
        status:"false",
        list: {}
      },
      MultiSelectMembers: 
      {
        value: [],
        closeOnSelect: true,
        options: [],
        searchable: true,
        createTag: false,
        inputType: "email",
        required: false,
        placeholder: "example@domain.com",
        hideSelected: true,
        showOptions : true
      },
      TimePicker:
      {
        date: new Date(),
        timezone: '',
        max_time:''
      },
      modelConfig: 
      {
        type: 'string',
        mask: 'YYYY-MM-DD', 
      },
      ListTimeSheets: 
      {
        status:"false",
        list: {}
      },
      MultiSelectProject: 
      {
        value: [],
        closeOnSelect: true,
        options: [],
        searchable: true,
        hideSelected: true,
        showOptions: true,
        required: true
      }
    }),
    methods: 
    {
      ActualDate()
      {
        let date = "";
        let year = new Date().getFullYear();
        let month = new Date().getMonth() + 1;
        let  month2 =  month>=10?month:'0'+ month;
        let day = new Date().getDate();
        date = year+"-"+month2+"-"+day;
        return date;
      },
      GetTimeSheet() 
      {
        Api.post("/timesheet/list",this.PostForm).then((result) => 
        {
          this.ListTimeSheets.status = result.data.status
          
          if(this.ListTimeSheets.status == "true")
          {
            this.ListTimeSheets.list = result.data.timesheets;
            this.$nextTick(() => 
            {
              this.InitDatatable()
            })
          }
        })
      },
      InitDatatable() 
      {
        var table = $('#timesheet-table').DataTable( 
        {
          dom: 'lftip',
          //dom: 'Blftip',
          responsive: true,
          destroy: true,
          lengthChange: false,
          aaSorting: [ [0,'asc'] ]
        });
      },
      RefreshDatatable() 
      {
        Api.post("/timesheet/list", this.PostForm).then((result) => 
        {
          this.ListTimeSheets.status = result.data.status
          
          if(this.ListTimeSheets.status == "true")
          {
            this.ListTimeSheets.list = result.data.timesheets;
          }
        })
      },
      DeleteTimeSheet(id) 
      {
        Api.get('/timesheet/delete/'+id+'').then((result) => 
        {
          if(result.data.status == "true")
          {
            $('#timesheet-table').DataTable().destroy()
            this.GetTimeSheet()
          }
        })
      },
      GetMembers() 
      {
        Api.post("/member/list/","").then((result) => 
        {
          this.ListMembers.status = result.data.status
          
          if(this.ListMembers.status == "true")
          {
            this.ListMembers.list =  result.data.member
            
            for (var i = 0; i <this.ListMembers.list.length; i++)
            {  
              if(this.ListMembers.list[i].description == "Worker")
              {
                var options =  {value: this.ListMembers.list[i].id_session, label: this.ListMembers.list[i].email, icon: this.ListMembers.list[i].picture, color: this.ListMembers.list[i].color}
                this.MultiSelectMembers.options.push(options)
              }
            }
          }
        })
      },
      GetProjects() 
      {
        Api.post("/project/list/","").then((result) => 
        {
          this.ListProjects.status = result.data.status
          
          if(this.ListProjects.status == "true")
          {
            this.ListProjects.list =  result.data.project
            
            for (var i = 0; i <this.ListProjects.list.length; i++)
            {  
              var options =  {value: this.ListProjects.list[i].id, label: this.ListProjects.list[i].name}
              this.MultiSelectProject.options.push(options)
            }
          }
        })
      },
      UpdateTimeSheetStatus(id) 
      {
        let Post = 
        {
          id:id,
        };
        Api.post("/timesheet/update", Post).then((result) => 
        {
          if(result.data.status == "true")
          {
            this.RefreshDatatable()
          }
        })
      },
    },
    mounted()
    {
      this.PostForm.date = this.ActualDate();
      
      this.GetTimeSheet()
    }
  }
</script>

<style scoped></style>
